import React, { useState } from 'react'
import {
  Autocomplete,
  Divider,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { Controller } from 'react-hook-form'

import {
  ErrorMessage,
  FormInput,
  FormInputWide,
  SubHeaderH3
} from '../../../components/styled/uiComponents'
import { CommonFormSectionProps, ICountry, ISemester } from '../../../types'
import { countryList, universityCountryList, universityList, yearList } from '../../../utils/utils'

const PersonalInfo: React.FC<CommonFormSectionProps & { watch: any }> = ({
  register,
  user,
  errors,
  control,
  metaData,
  formValues
}) => {
  const [cursusPresent, setCursusPresent] = useState<boolean>(false)

  const onChangeSchool = (value: any) => {
    setCursusPresent(value.length > 0)
  }

  return (
    <Grid container spacing={3} direction='row' justifyItems='flex-start' alignItems='stretch'>
      <Grid item xs={12}>
        <p>
          Lääkäriliitto tarjoaa lääketieteen opiskelijoille Lääkärilehden ja Fimnet-tunnukset
          ensimmäisestä opiskeluvuodesta lähtien. Ilmoita tietosi Lääkäriliittoon tällä lomakkeella.
        </p>
        <p>Pakolliset kentät on merkitty *-merkillä.</p>
        <Typography variant='h4' color='primary'>
          Henkilötiedot
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Sukunimi</FormLabel>
        <p {...register('lastname')}>{user.lastname}</p>
        <ErrorMessage>{errors?.lastname?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Etunimet</FormLabel>
        <p {...register('firstname')}>{user.firstname}</p>
        <ErrorMessage>{errors?.firstname?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Kutsumanimi*</FormLabel>
        <FormInput
          {...register('nickname')}
          defaultValue={formValues?.nickname || user.firstname.split(' ')[0] || ''}
        />
        <ErrorMessage>{errors.nickname?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Henkilötunnus</FormLabel>
        <p {...register('els_hetu')}>{user.els_hetu}</p>
        <ErrorMessage>{errors.els_hetu?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <div style={{ borderTop: '1px dotted black' }}>
          <SubHeaderH3>Yhteystiedot</SubHeaderH3>
        </div>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postiosoite*</FormLabel>
        <FormInputWide
          {...register('address.line1')}
          defaultValue={formValues?.address?.line1 || ''}
        />
        <ErrorMessage>{errors.address?.line1?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postiosoitteen rivi 2 (vapaaehtoinen)</FormLabel>
        <FormInputWide
          {...register('address.line2')}
          defaultValue={formValues?.address?.line2 || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postiosoitteen rivi 3 (vapaaehtoinen)</FormLabel>
        <FormInputWide
          {...register('address.line3')}
          defaultValue={formValues?.address?.line3 || ''}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Postinumero*</FormLabel>
        <FormInput
          {...register('address.postalcode')}
          defaultValue={formValues?.address?.postalcode || ''}
        />
        <ErrorMessage>{errors.address?.postalcode?.message}</ErrorMessage>
      </Grid>
      <Grid item md={2}></Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Postitoimipaikka*</FormLabel>
        <FormInput {...register('address.city')} defaultValue={formValues?.address?.city || ''} />
        <ErrorMessage>{errors.address?.city?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Postituskieli*</FormLabel>
        <Select
          {...register('address.els_postikieli')}
          displayEmpty
          fullWidth
          defaultValue={
            formValues?.address?.els_postikieli ||
            JSON.stringify(
              (lang => (lang ? { attributevalue: lang.attributevalue, value: lang.value } : null))(
                metaData.postikielet.find((lang: any) => lang.value === 'Suomi')
              )
            )
          }>
          {metaData.postikielet.map((mailLanguage: any, i: number) => (
            <MenuItem
              key={i}
              value={JSON.stringify({
                attributevalue: mailLanguage.attributevalue,
                value: mailLanguage.value
              })}>
              {mailLanguage.value}
            </MenuItem>
          ))}
        </Select>
        <ErrorMessage>{errors.address?.els_postikieli?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Maa*</FormLabel>
        <Select
          {...register('address.country')}
          displayEmpty
          fullWidth
          defaultValue={
            formValues?.address?.country ||
            JSON.stringify(
              (country =>
                country
                  ? { els_koodiid: country.els_koodiid, els_koodinnimi: country.els_koodinnimi }
                  : null)(countryList.find(country => country.els_koodinnimi === 'Suomi'))
            )
          }>
          {countryList.map((country, i: number) => (
            <MenuItem
              key={i}
              value={JSON.stringify({
                els_koodiid: country.els_koodiid,
                els_koodinnimi: country.els_koodinnimi
              })}>
              {country.els_koodinnimi}
            </MenuItem>
          ))}
        </Select>
        <ErrorMessage>{errors.address?.country?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Matkapuhelinnumero*</FormLabel>
        <FormInput {...register('mobilephone')} defaultValue={formValues?.mobilephone || ''} />
        <p>Puhelinnumeron tulee olla kansainvälisessä muodossa (+358).</p>
        <ErrorMessage>{errors.mobilephone?.message}</ErrorMessage>
      </Grid>
      <Grid item md={2}></Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>Sähköpostiosoite*</FormLabel>
        <FormInput {...register('emailaddress1')} defaultValue={formValues?.emailaddress1 || ''} />
        <ErrorMessage>{errors.emailaddress1?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <div style={{ borderTop: '1px dotted black' }}>
          <SubHeaderH3>Opiskelutiedot</SubHeaderH3>
        </div>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Oppilaitos*</FormLabel>
        <Controller
          name='cursus.els_oppilaitos'
          control={control}
          defaultValue={formValues?.cursus?.els_oppilaitos || ''}
          render={({ field }) => (
            <Select
              {...field}
              displayEmpty
              fullWidth
              onChange={e => {
                field.onChange(e)
                onChangeSchool(e.target.value)
              }}
              defaultValue={formValues?.cursus?.els_oppilaitos || ''}>
              {universityList.map((school: any, i: number) => (
                <MenuItem
                  key={i}
                  value={JSON.stringify({
                    els_koodiid: school.els_koodiid,
                    els_koodinnimi: school.els_koodinnimi
                  })}>
                  {school.els_koodinnimi}
                </MenuItem>
              ))}
              <Divider />
              {universityCountryList.map((country: any, i: number) => (
                <MenuItem
                  key={i}
                  value={JSON.stringify({
                    els_koodiid: country.els_koodiid,
                    els_koodinnimi: country.els_koodinnimi
                  })}>
                  {country.els_koodinnimi}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <ErrorMessage>{errors.cursus?.els_oppilaitos?.message}</ErrorMessage>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12} md={6}>
              <FormLabel>Aloitusvuosi*</FormLabel>
              <Controller
                name='cursus.els_aloitusvuosi'
                control={control}
                defaultValue={formValues?.cursus?.els_aloitusvuosi || 'none'}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={!cursusPresent}
                    fullWidth
                    defaultValue={formValues?.cursus?.els_aloitusvuosi || 'none'}>
                    <MenuItem value='none'>{'---Valitse---'}</MenuItem>
                    {yearList(true).map(year => (
                      <MenuItem value={`${year}`} key={year}>
                        {year.toString()}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <ErrorMessage>{errors.cursus?.els_aloitusvuosi?.message}</ErrorMessage>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12} md={6}>
              <FormLabel>Lukukausi*</FormLabel>
              <Controller
                name='cursus.els_lukukausi'
                control={control}
                defaultValue={formValues?.cursus?.els_lukukausi || ''}
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={!cursusPresent}
                    displayEmpty
                    fullWidth
                    defaultValue={formValues?.cursus?.els_lukukausi || ''}>
                    {metaData.lukukaudet.map((semester: ISemester) => (
                      <MenuItem
                        key={semester.attributevalue}
                        value={JSON.stringify({
                          attributevalue: semester.attributevalue,
                          value: semester.value
                        })}>
                        {semester.value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <ErrorMessage>{errors.cursus?.els_lukukausi?.message}</ErrorMessage>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PersonalInfo
